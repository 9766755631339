import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Spin } from 'antd';
import classNames from 'classnames';
import usePosPrinter from 'commons/hooks/use-pos-printer';
import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { posPrinterSetting } from 'utils/connect-to-pos-printer';

const PosPrinterSettingModal = ({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm<{
    host: string;
    port: string;
  }>();
  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        host: posPrinterSetting.host.get(),
        port: posPrinterSetting.port.get(),
      });
    }
  }, [form, open]);

  return (
    <Modal
      open={open}
      title="小票打印机设置"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((data) => {
            posPrinterSetting.host.set(data.host);
            posPrinterSetting.port.set(data.port);
            window.location.reload();
          })
          .catch((err) => {});
      }}
    >
      <Form form={form}>
        <Form.Item name="host" label="Host" rules={[{ required: true }]}>
          <Input></Input>
        </Form.Item>
        <Form.Item name="port" label="Port" rules={[{ required: true }]}>
          <Input></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const PosPrinterInfo = () => {
  const { posPrinterInfo } = usePosPrinter();
  const [open, toggleOpen] = useToggle(false);

  if (!posPrinterInfo || posPrinterInfo.status === 'connecting') {
    return (
      <Spin indicator={<LoadingOutlined spin />}>
        <PrinterOutlined style={{ fontSize: '20px' }} />
      </Spin>
    );
  }

  return (
    <div className="cursor-pointer">
      <PosPrinterSettingModal open={open} onCancel={toggleOpen} />
      <PrinterOutlined
        onClick={toggleOpen}
        style={{ fontSize: '20px' }}
        className={classNames(
          posPrinterInfo.status === 'connecte_success'
            ? 'text-green-500'
            : 'text-red-500'
        )}
      />
    </div>
  );
};

export default PosPrinterInfo;
