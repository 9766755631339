import { Button } from 'antd';
import i18n from 'i18n';
import { OmsOrderDetail } from 'types/oms';
import { SHOP_MAP } from 'commons/options';
import usePosPrinter from 'commons/hooks/use-pos-printer';
import printOrderReceipt from 'utils/print-order-receipt';

const PrintReceiptButton = ({
  omsOrderDetail,
  onPrintReceiptByBrowser,
}: {
  omsOrderDetail: OmsOrderDetail;
  onPrintReceiptByBrowser: () => void;
}) => {
  const { createdFrom } = omsOrderDetail;
  const { posPrinterInfo } = usePosPrinter();

  // 目前只开发了日本银座店的小票功能
  if (createdFrom !== SHOP_MAP.GINZA) {
    return null;
  }

  return (
    <Button
      loading={posPrinterInfo?.status === 'connecting'}
      type="primary"
      onClick={async () => {
        if (!posPrinterInfo?.printer) {
          onPrintReceiptByBrowser();
        } else {
          printOrderReceipt({ orderId: omsOrderDetail.id });
        }
      }}
    >
      {i18n.t('print_receipt')}
    </Button>
  );
};

export default PrintReceiptButton;
