import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetOrderListDto, getOrderList } from 'apis/oms';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  SHOP_MAP,
  findLabelByValue,
} from 'commons/options';
import LinkButton from 'components/link-button';
import showTotal from 'components/show-total';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { PageQuery } from 'types/base';
import { OmsOrder } from 'types/oms';
import formatTime from 'utils/formatTime';
import setQueryParameters from 'utils/setQueryParameters';
import queryString from 'query-string';
import { selectUserInfo } from 'store/slices/userInfoSlice';

const OrderList = () => {
  const [form] = Form.useForm<
    Omit<GetOrderListDto, 'createTime'> & {
      createTime: Dayjs;
    }
  >();
  const navigate = useNavigate();

  const { orderTypeOptions } = useAppSelector(selectGlobalInfo);
  const { shop } = useAppSelector(selectUserInfo);

  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<OmsOrder>();
  const { orderStatusOptions } = useAppSelector(selectGlobalInfo);

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const formData = form.getFieldsValue();
      const data = {
        ...form.getFieldsValue(),
        createTime: formData.createTime
          ? formData.createTime.format('YYYY-MM-DD')
          : undefined,
        pageNum,
        pageSize,
      };

      setQueryParameters(data);

      try {
        setLoading(true);
        const {
          data: { list, total },
        } = await getOrderList(data);

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed.status) {
      // @ts-ignore
      parsed.status = +parsed.status;
    }

    if (parsed.createdFroms) {
      // @ts-ignore
      parsed.createdFroms = parsed.createdFroms.split(',').map((i) => {
        return +i;
      });
    } else if (shop) {
      // @ts-ignore
      parsed.createdFroms = [shop];
    }

    if (parsed.createTime) {
      // @ts-ignore
      parsed.createTime = dayjs(parsed.createTime);
    }

    form.setFieldsValue(parsed);
    const pageNum = parsed.pageNum ? +parsed.pageNum : 1;
    const pageSize = parsed.pageSize ? +parsed.pageSize : 10;
    setPageNum(pageNum);
    setPageSize(pageSize);

    getDataSource({ pageNum, pageSize });
  }, [form, getDataSource, setPageNum, setPageSize, shop]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const columns: ColumnsType<OmsOrder> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.order_sn}></Trans>,
        dataIndex: 'orderSn',
        key: 'orderSn',
        width: 180,
        render: (orderSn: OmsOrder['orderSn'], omsOrder: OmsOrder) => {
          return (
            <LinkButton href={`/oms/order-view/${omsOrder.id}`}>
              {orderSn}
            </LinkButton>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        dataIndex: 'orderItemList',
        key: 'orderItemList',
        width: 150,
        render: (orderItemList: OmsOrder['orderItemList']) => {
          return (
            <div>
              {orderItemList.map(({ productSnDes, productId }) => {
                const href = `/pms/product-view/${productId}`;
                return (
                  <div key={productSnDes}>
                    <a
                      href={href}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(href);
                      }}
                    >
                      {productSnDes}
                    </a>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.email} />,
        key: 'memberUsername',
        width: 220,
        render: ({ memberUsername, memberId }: OmsOrder) => {
          if (!memberId) return '-';

          return (
            <LinkButton href={`/ums/member-view/${memberId}`}>
              {memberUsername}
            </LinkButton>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.order_type} />,
        dataIndex: 'orderType',
        key: 'orderType',
        width: 100,
        render: (orderType: OmsOrder['payAmount']) => {
          return findLabelByValue(orderType, orderTypeOptions);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.pay_amount}></Trans>,
        dataIndex: 'payAmount',
        key: 'payAmount',
        width: 150,
        render: (
          payAmount: OmsOrder['payAmount'],
          {
            payAmountActualCurrency,
            orderItemList: [{ actualCurrency }],
          }: OmsOrder
        ) => {
          if (actualCurrency && payAmountActualCurrency !== null) {
            return `${actualCurrency} ${payAmountActualCurrency.toLocaleString()}`;
          }

          return `JPY ${payAmount.toLocaleString()}`;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_from}></Trans>,
        dataIndex: 'createdFrom',
        key: 'createdFrom',
        width: 100,
        render: (createdFrom: OmsOrder['createdFrom']) => {
          return findLabelByValue(createdFrom, ORDER_CREATED_FROM_OPTION_LIST);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_time}></Trans>,
        dataIndex: 'createTime',
        key: 'createTime',
        width: 200,
        render: (createTime: OmsOrder['createTime']) => {
          return formatTime(createTime);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.MultiplePayList}></Trans>,
        dataIndex: 'multiplePayStatus',
        width: 100,
        key: 'multiplePayStatus',
        render: (value: OmsOrder['multiplePayStatus']) => {
          return value ? <Trans i18nKey={LOCALS.yes} /> : '-';
        },
      },
      {
        title: <Trans i18nKey={LOCALS.order_status}></Trans>,
        dataIndex: 'status',
        width: 120,
        key: 'status',
        fixed: 'right',
        render: (status: OmsOrder['status']) => {
          return (
            <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
              {findLabelByValue(status, orderStatusOptions)}
            </Tag>
          );
        },
      },
    ];
  }, [navigate, orderStatusOptions, orderTypeOptions]);

  return (
    <div>
      <Form form={form} layout={'inline'} onFinish={onFinish}>
        <Form.Item label={<Trans i18nKey={LOCALS.order_sn} />} name="orderSn">
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.product_sn} />}
          name="productSn"
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.created_time} />}
          name="createTime"
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.created_from} />}
          name="createdFroms"
        >
          {/* TODO: 不同店铺账号之间数据权限，只能查看自己店铺的订单 */}
          <Select
            mode="multiple"
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select)}
            options={ORDER_CREATED_FROM_OPTION_LIST.filter((d) =>
              [
                SHOP_MAP.WEBSITE,
                SHOP_MAP.GINZA,
                SHOP_MAP.HONGKONG,
                SHOP_MAP.SINGAPORE,
                SHOP_MAP.SNS,
              ].includes(d.value)
            )}
          />
        </Form.Item>

        <Form.Item
          label={<Trans i18nKey={LOCALS.order_status} />}
          name="status"
        >
          <Select
            allowClear
            placeholder={i18n.t(LOCALS.please_select)}
            options={orderStatusOptions}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>

            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <Table
        bordered
        tableLayout="fixed"
        pagination={{
          showTotal,
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default OrderList;
