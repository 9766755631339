export const posPrinterSetting = {
  host: {
    get() {
      return (
        window.localStorage.getItem('posPrinterSettingHost') ||
        'epsona4182c.local'
      );
    },
    set(value: string) {
      window.localStorage.setItem('posPrinterSettingHost', value);
    },
  },

  port: {
    get() {
      return window.localStorage.getItem('posPrinterSettingPort') || '8043';
    },
    set(value: string) {
      window.localStorage.setItem('posPrinterSettingPort', value);
    },
  },
};

/**
 * @description 初始化小票打印机，在加载页面最开始的时候执行
 */
const connectToPosPrinter = () => {
  // 连接中 ｜ 连接成功，直接返回
  if (
    window.posPrinterInfo &&
    (window.posPrinterInfo.status === 'connecting' ||
      window.posPrinterInfo.status === 'connecte_success')
  )
    return;

  window.posPrinterInfo = {
    status: 'connecting',
  };

  const ePosDev = new window.epson.ePOSDevice();
  ePosDev.connect(
    posPrinterSetting.host.get(),
    posPrinterSetting.port.get(),
    (res) => {
      if (res !== 'OK' && res !== 'SSL_CONNECT_OK') {
        window.posPrinterInfo = {
          status: 'connecte_failed',
        };
        return;
      }

      ePosDev.createDevice(
        'local_printer',
        ePosDev.DEVICE_TYPE_PRINTER,
        {
          crypto: false,
          buffer: false,
        },
        (printer, status: string) => {
          if (status !== 'OK') {
            window.posPrinterInfo = {
              status: 'connecte_failed',
            };
            return;
          }

          printer.timeout = 60000;
          window.posPrinterInfo = {
            status: 'connecte_success',
            printer,
          };
        }
      );
    }
  );
};

export default connectToPosPrinter;
