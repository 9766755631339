import { Table, Image, Descriptions } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { OmsOrderDetail, OmsOrderItem } from 'types/oms';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import LinkButton from 'components/link-button';
import {
  CHECKOUT_OUT_PRODUCT_PRICE_TYPE,
  CHECKOUT_OUT_PRODUCT_PRICE_TYPE_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import { useDescProps } from 'commons/hooks/useDescProps';

type OrderItemListProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderItemList = ({
  omsOrderDetail: { orderItemList },
}: OrderItemListProps) => {
  const isMobile = false;

  const columns: ColumnsType<OmsOrderItem> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.product_sn} />,
        dataIndex: 'productSnDes',
        key: 'productSnDes',
        width: 100,
        render: (
          productSnDes: OmsOrderItem['productSnDes'],
          omsOrderItem: OmsOrderItem
        ) => {
          return (
            <LinkButton href={`/pms/product-view/${omsOrderItem.productId}`}>
              {productSnDes}
            </LinkButton>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        dataIndex: 'productPic',
        key: 'productPic',
        width: 150,
        render: (productPic: OmsOrderItem['productPic']) => {
          return productPic ? (
            <img width={100} src={productPic} alt={productPic} />
          ) : (
            '-'
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_name} />,
        dataIndex: 'productName',
        key: 'productName',
        width: 150,
      },
      {
        title: <Trans i18nKey={LOCALS.price} />,
        dataIndex: 'productPrice',
        key: 'productPrice',
        width: 150,
        render: (
          productPic: OmsOrderItem['productPrice'],
          { actualCurrency, realAmountCurrency }: OmsOrderItem
        ) => {
          if (
            actualCurrency &&
            realAmountCurrency !== undefined &&
            realAmountCurrency !== null
          ) {
            return `${actualCurrency} ${realAmountCurrency.toLocaleString()}`;
          }

          return `${'JPY'} ${productPic.toLocaleString()}`;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.price_type} />,
        dataIndex: 'isTaxFree',
        key: 'isTaxFree',
        width: 150,
        render: (isTaxFree: OmsOrderItem['isTaxFree']) => {
          return findLabelByValue(
            isTaxFree
              ? CHECKOUT_OUT_PRODUCT_PRICE_TYPE.WITH_OUT_TAX
              : CHECKOUT_OUT_PRODUCT_PRICE_TYPE.WITH_TAX,
            CHECKOUT_OUT_PRODUCT_PRICE_TYPE_OPTION_LIST
          );
        },
      },
    ];
  }, []);

  const descProps = useDescProps({});

  return (
    <div>
      {isMobile ? (
        <>
          <Descriptions
            title={<Trans i18nKey={LOCALS.product_info} />}
          ></Descriptions>
          {orderItemList.map((d) => (
            <div className="mb-2" key={d.id}>
              <Descriptions bordered {...descProps}>
                <Descriptions.Item
                  label={<Trans i18nKey={LOCALS.product_sn} />}
                >
                  <LinkButton href={`/pms/product-view/${d.productId}`}>
                    {d.productSn}
                  </LinkButton>
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Trans i18nKey={LOCALS.product_pictures} />}
                >
                  {d.productPic ? (
                    <Image width={100} src={d.productPic} alt={d.productPic} />
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Trans i18nKey={LOCALS.product_name} />}
                >
                  {d.productName}
                </Descriptions.Item>

                <Descriptions.Item label={<Trans i18nKey={LOCALS.price} />}>
                  JPY{` ${d.productPrice.toLocaleString()}`}
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Trans i18nKey={LOCALS.price_type} />}
                >
                  {findLabelByValue(
                    d.isTaxFree
                      ? CHECKOUT_OUT_PRODUCT_PRICE_TYPE.WITH_OUT_TAX
                      : CHECKOUT_OUT_PRODUCT_PRICE_TYPE.WITH_TAX,
                    CHECKOUT_OUT_PRODUCT_PRICE_TYPE_OPTION_LIST
                  )}
                </Descriptions.Item>
              </Descriptions>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={styles.title}>
            <Trans i18nKey={LOCALS.product_info} />
          </div>
          <Table
            size="small"
            tableLayout="fixed"
            pagination={false}
            rowKey={'id'}
            dataSource={orderItemList}
            columns={columns}
          ></Table>
        </>
      )}
    </div>
  );
};

export default OrderItemList;
